import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import ClientImg from "../assets/ceo/CEONexkites.jpg"; // Example client image
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ClientDashboard = () => {
  const [activeSection, setActiveSection] = useState("Overview");
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login-page");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "Overview":
        return (
          <>
            {/* Ongoing Projects with Progress Bar */}
            <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
              <h2 className="text-lg font-bold mb-4">Ongoing Projects</h2>
              {[
                { name: "E-commerce Website", deadline: "Dec 15, 2024", progress: 70 },
                { name: "Mobile App", deadline: "Dec 25, 2024", progress: 50 },
                { name: "Portfolio Website", deadline: "Jan 10, 2025", progress: 85 },
              ].map((project, index) => (
                <div className="mb-4" key={index}>
                  <p className="font-semibold">{project.name}</p>
                  <p className="text-sm text-gray-600">Deadline: {project.deadline}</p>
                  <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
                    <div
                      className="h-4 rounded-full"
                      style={{
                        width: `${project.progress}%`,
                        backgroundColor: project.progress > 60 ? "#4caf50" : "#2196f3", // Green if progress > 60%, blue otherwise
                      }}
                    ></div>
                  </div>
                  <p className="text-sm text-gray-500 mt-1">Progress: {project.progress}%</p>
                </div>
              ))}
            </div>


            {/* Project Analytics Section */}
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-bold mb-4">Project Status</h2>
                <Pie
                  data={{
                    labels: ["Completed", "Ongoing", "Pending"],
                    datasets: [
                      {
                        data: [5, 3, 2],
                        backgroundColor: ["#4caf50", "#2196f3", "#ff9800"],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                    },
                  }}
                />
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-bold mb-4">Monthly Progress</h2>
                <Bar
                  data={{
                    labels: ["January", "February", "March", "April", "May"],
                    datasets: [
                      {
                        label: "Projects Completed",
                        data: [3, 4, 2, 5, 6],
                        backgroundColor: "#2196f3",
                      },
                      {
                        label: "Projects In Progress",
                        data: [1, 2, 3, 2, 1],
                        backgroundColor: "#ff9800",
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    scales: {
                      x: { title: { display: true, text: "Months" } },
                      y: { title: { display: true, text: "Projects" } },
                    },
                  }}
                />
              </div>
            </section>
          </>
        );
      default:
        return <p>Select a section to view its content.</p>;
    }
  };

  return (
    <>
      <Navbar className="mb-16" />
      <div className="flex mt-16   min-h-screen bg-gray-100">
        {/* Sidebar */}
        <aside className="w-full lg:w-64 bg-white shadow-lg lg:h-screen fixed lg:sticky top-0">
          <div className="p-6 text-center">
            <img
              src={ClientImg}
              alt="Client"
              className="w-24 h-24 rounded-full mx-auto mb-4"
            />
            <h3 className="text-lg font-semibold text-gray-800">Rahul gupta</h3>
            <p className="text-sm text-gray-500">ID: CLT12345</p>
          </div>
          <nav className="mt-8">
            {["Overview", "Messages", "Projects", "Notifications", "Profile", "Settings"].map((item) => (
              <button
                key={item}
                onClick={() => setActiveSection(item)}
                className={`block px-6 py-3 text-left w-full text-gray-600 hover:bg-cyan-50 hover:text-cyan-600 ${activeSection === item ? "bg-cyan-50 text-cyan-600" : ""
                  }`}
              >
                {item}
              </button>
            ))}
            <button
              className="block px-6 py-3 text-left w-full text-gray-600 hover:bg-cyan-50 hover:text-cyan-600 mt-8"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="flex-1 p-8 lg:p-12 overflow-y-auto ml-0 lg:ml-64">{renderContent()}</main>
      </div>
      <Footer />
    </>
  );
};

export default ClientDashboard;
