import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import contactimg from "../assets/logo/Nexkites-kite.png";
import ProgressSlider from '../constants/ProgressSlider';

const AuthPage = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [userType, setUserType] = useState('client');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        loginId: '',
        password: '',
        confirmPassword: '',
    });
    const [passwordVisible, setPasswordVisible] = useState(false);

    const navigate = useNavigate();

    const users = {
        admin: { loginId: 'akashsadashiv', password: 'theceoakash0100' },
        client: { loginId: 'client', password: 'client123client123' },
        employee: { loginId: 'employee', password: 'employee123employee123' },
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isLogin) {
            const { loginId, password } = formData;
            const user = users[userType];
            if (user && user.loginId === loginId && user.password === password) {
                const route = {
                    client: '/ClientDashboard',
                    admin: '/AdminDashboard',
                    employee: '/EmployeeDashboard',
                }[userType];
                navigate(route);
            } else {
                alert('Invalid credentials. Please try again.');
            }
        } else {
            if (
                formData.name &&
                formData.email &&
                formData.password &&
                formData.password === formData.confirmPassword
            ) {
                alert('Account created successfully!');
                navigate('/ClientDashboard');
            } else {
                alert('Please ensure all fields are correctly filled.');
            }
        }
    };

    return (
        <>
            <Navbar className='mb-20' />
            <section className="flex mt-20 items-center justify-center min-h-screen px-4 py-6 sm:px-6 lg:px-8 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)]">
                <div className="flex flex-col items-center w-full max-w-screen-sm md:max-w-screen-md lg:max-w-lg bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="w-full p-6 sm:p-8 flex flex-col items-center space-y-6">
                        <h1 className="text-lg sm:text-2xl md:text-3xl font-semibold text-gray-500 text-center">
                            {isLogin ? 'Login to Your Account' : 'Create Your Account'}
                        </h1>

                        {/* Toggle Buttons */}
                        <div className="flex w-full justify-center gap-4 border-b pb-2">
                            <button
                                onClick={() => setIsLogin(true)}
                                className={`text-sm font-medium pb-2 ${isLogin ? 'text-cyan-500 border-b-2 border-cyan-500' : 'text-gray-500'}`}
                            >
                                Login
                            </button>
                            {/* <button
                                onClick={() => setIsLogin(false)}
                                className={`text-sm font-medium pb-2 ${!isLogin ? 'text-cyan-500 border-b-2 border-cyan-500' : 'text-gray-500'}`}
                            >
                                Register
                            </button> */}
                        </div>

                        {/* Form */}
                        <form onSubmit={handleSubmit} className="w-full space-y-4">
                            {/* User Type Selector */}
                            <div className="flex flex-wrap gap-3 justify-center p-2">
                                {['Admin', 'Client', 'Employee'].map((type) => (
                                    <button
                                        key={type}
                                        type="button"
                                        onClick={() => setUserType(type.toLowerCase())}
                                        className={`flex items-center gap-2 px-4 py-2 text-sm font-medium justify-center transition relative ${userType === type.toLowerCase()
                                            ? 'text-cyan-500 border-b-2 border-cyan-500'
                                            : 'text-gray-600 hover:text-cyan-400'
                                            }`}
                                    >
                                        {type}
                                        {userType === type.toLowerCase() && (
                                            <span className="absolute bottom-0 left-0 right-0 h-1 bg-cyan-500 rounded"></span>
                                        )}
                                    </button>
                                ))}
                            </div>
                            <p className="text-center text-red-500 text-xs">
                                Note: Please select a valid user type to go further.
                            </p>

                            {/* Conditional Fields */}
                            {!isLogin && (
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Full Name"
                                    className="w-full px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-cyan-400"
                                    required={!isLogin}
                                />
                            )}

                            {!isLogin && (
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    className="w-full px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-cyan-400"
                                    required={!isLogin}
                                />
                            )}

                            {isLogin && (
                                <input
                                    type="text"
                                    name="loginId"
                                    value={formData.loginId}
                                    onChange={handleChange}
                                    placeholder="Enter your Login ID"
                                    className="w-full px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-cyan-400"
                                    required={isLogin}
                                />
                            )}

                            {/* Password */}
                            <div className="relative">
                                <input
                                    type={passwordVisible ? 'text' : 'password'}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    className="w-full px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-cyan-400"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-600 text-sm"
                                >
                                    {passwordVisible ? 'Hide' : 'Show'}
                                </button>
                            </div>

                            {!isLogin && (
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    placeholder="Confirm Password"
                                    className="w-full px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-cyan-400"
                                    required={!isLogin}
                                />
                            )}

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full py-3 bg-black text-white rounded-lg shadow-md hover:bg-gray-800 transition"
                            >
                                {isLogin ? `Login as ${userType}` : `Register as ${userType}`}
                            </button>

                            {/* Social Logins */}
                            <div className="flex flex-col items-center gap-4 mt-4">
                                <p className="text-gray-600 text-sm">Or continue with</p>
                                <div className="flex gap-3">
                                    <button className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-700 transition text-sm">
                                        <i className="fab fa-google text-red-500"></i> Google
                                    </button>
                                    <button className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg text-gray-700 transition text-sm">
                                        <i className="fab fa-facebook text-blue-600"></i> Meta
                                    </button>
                                </div>
                            </div>
                        </form>

                        <p className="text-sm text-gray-500 text-center">
                            {isLogin ? 'Don’t have an account?' : 'Already have an account?'}{' '}
                            <button
                                onClick={() => setIsLogin(!isLogin)}
                                className="text-cyan-500 underline"
                            >
                                {isLogin ? 'Sign up' : 'Log in'}
                            </button>
                        </p>

                        {/* Go to Home Button */}
                        <button
                            onClick={() => navigate('/')}
                            className="mt-4 py-2 px-6 bg-black text-white rounded-lg shadow hover:bg-black transition"
                        >
                            Go to Home
                        </button>
                    </div>
                </div>
            </section>
            <Footer />
            <ProgressSlider />
        </>
    );
};

export default AuthPage;
