import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BMimg0 from "../assets/landing/EditorAru/EditorAru0.jpg";
import BMimg1 from "../assets/landing/EditorAru/EditorAru1.jpg";
import BMimg2 from "../assets/landing/EditorAru/EditorAru2.jpg";
import BMimg3 from "../assets/landing/EditorAru/EditorAru3.jpg";
import BMimg4 from "../assets/landing/EditorAru/EditorAru4.jpg";

const EditorAru = () => {
  const slides = [
    {
      image: BMimg0,
      heading: "Preserve Your Memories!",
      subheading: "Professional photography crafted for you.",
      buttonText: "Explore EditorAru",
      link: "https://editoraru.in/",
    },
    {
      image: BMimg1,
      heading: "Capture Life's Moments!",
      subheading: "Artistic photo editing and creative shots.",
      buttonText: "Explore EditorAru",
      link: "https://editoraru.in/",
    },
    {
      image: BMimg2,
      heading: "Experience Visual Excellence!",
      subheading: "High-quality photography and timely delivery.",
      buttonText: "Explore EditorAru",
      link: "https://editoraru.in/",
    },
    {
      image: BMimg3,
      heading: "Your Story, Our Lens!",
      subheading: "Wide range of packages to suit every need.",
      buttonText: "Explore EditorAru",
      link: "https://editoraru.in/",
    },
    {
      image: BMimg4,
      heading: "Moments That Last Forever!",
      subheading: "Turning moments into timeless art.",
      buttonText: "Explore EditorAru",
      link: "https://editoraru.in/",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 2000); // Change slide every 2 seconds

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <section className="bg-peach-100 text-center px-4 py-10 sm:px-8 lg:px-12 shadow-sm mt-10 rounded">
      <div className="relative z-10">
        <h1 className="text-gray-400 font-800 text-3xl sm:text-3xl lg:text-4xl xl:text-4xl leading-tight">
          {slides[currentIndex].heading}
        </h1>

        {/* Image */}
        <div className="flex justify-center my-8">
          <img
            src={slides[currentIndex].image}
            alt="EditorAru Showcase"
            className="max-w-full w-auto h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Subtitle */}
        <p className="text-xl text-gray-600 font-light">
          {slides[currentIndex].subheading}
        </p>

        {/* Button */}
        <div className="mt-6">
          <a href={slides[currentIndex].link} target="_blank" rel="noopener noreferrer">
            <button className="bg-black text-white rounded-md px-6 py-2 font-800 text-sm sm:px-8 sm:py-3 md:px-10 md:py-4 hover:bg-purple-600 transition duration-300">
              {slides[currentIndex].buttonText}
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default EditorAru;