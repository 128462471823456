import React from "react";
import aboutimg from "../assets/logo/Nexkites KiteBlue.png";
import Footer from "../constants/Footer";
import Navbar from "../constants/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faBullseye, faHandsHelping, faRocket } from "@fortawesome/free-solid-svg-icons";
import "../index.css";
import ProgressSlider from "../constants/ProgressSlider";

const About = () => {
    const values = [
        {
            title: "Innovation",
            description: "We drive innovation to craft cutting-edge, future-ready solutions that redefine possibilities.",
            icon: faRocket,
            bgColor: "bg-blue-50",
            iconColor: "text-blue-600",
        },
        {
            title: "Quality",
            description: "Every solution we deliver is built with precision, quality, and unmatched attention to detail.",
            icon: faBullseye,
            bgColor: "bg-green-50",
            iconColor: "text-green-600",
        },
        {
            title: "Collaboration",
            description: "Collaboration is our cornerstone, enabling seamless communication with clients and within our team.",
            icon: faHandsHelping,
            bgColor: "bg-yellow-50",
            iconColor: "text-yellow-600",
        },
        {
            title: "Velocity",
            description: "We prioritize speed and efficiency without compromising the highest standards of service and quality.",
            icon: faHistory,
            bgColor: "bg-purple-50",
            iconColor: "text-purple-600",
        },
    ];

    return (
        <>
            <Navbar />

            {/* About Section */}
            <div className="container mx-auto py-16 px-4 md:px-8 lg:px-16 mt-12 md:mt-16 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] flex flex-col items-center selection:bg-cyan-300 selection:text-cyan-900">

                {/* Heading */}
                <h1 className="text-center text-4xl text-gray-700 font-semibold text-center p-5 mb-10">
                    About <span className="text-cyan-500">Nexkites</span>
                </h1>

                {/* Image & Overview */}
                <div className="flex flex-col md:flex-row items-center justify-center bg-white gap-6 md:gap-10 p-6 rounded-2xl shadow-lg">
                    <div className="w-full md:w-1/2 flex justify-center">
                        <img
                            src={aboutimg}
                            alt="About Nexkites"
                            className="w-72 h-72 md:w-80 md:h-80"
                        />
                    </div>
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h2 className="text-xl md:text-2xl font-800 text-gray-800 leading-relaxed mb-6">
                            At Nexkites, we bridge ideas and innovation to deliver transformative digital experiences. From websites to mobile apps, we empower businesses with tailor-made solutions that inspire and engage.
                        </h2>
                        <p className="text-gray-500 italic">"Turning your vision into reality." — Team Nexkites</p>
                    </div>
                </div>

                {/* Our Story */}
                <div className="flex flex-col md:flex-row items-center justify-center bg-white mt-16 gap-6 md:gap-10 p-6 rounded-2xl shadow-lg bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] flex flex-col items-center selection:bg-cyan-300 selection:text-cyan-900">
                    <div className="w-full md:w-1/2 text-center">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-5">
                            Our Story
                        </h2>
                        <p className="text-gray-600 leading-relaxed text-lg">
                            Founded in October 2024 by <span className="font-800">Akash Sadashiv</span>, Nexkites was born out of a passion for innovation and a commitment to revolutionizing digital experiences. Starting with a small, ambitious team, we’ve expanded to serve clients globally, helping brands thrive in an ever-evolving digital landscape.
                        </p>
                    </div>
                </div>

                {/* Mission */}
                <div className="flex flex-col md:flex-row bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] items-center justify-center mt-16 gap-6 md:gap-10 p-6 rounded-2xl shadow-lg">
                    <div className="w-full md:w-1/2 text-center">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-5">
                            Our Mission
                        </h2>
                        <p className="text-gray-600 leading-relaxed text-lg">
                            To empower businesses by building high-performing digital products that drive user engagement, elevate brand value, and achieve measurable success.
                        </p>
                    </div>
                </div>

                {/* Core Values */}
                <div className="flex flex-col items-center justify-center mt-16 gap-6 md:gap-10 p-5">
                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-5">
                        Core Values
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4 md:px-6">
                        {values.map((value, index) => (
                            <div
                                key={index}
                                className={`flex flex-col items-center text-center p-6 shadow-lg rounded-lg ${value.bgColor}`}
                            >
                                <FontAwesomeIcon
                                    icon={value.icon}
                                    className={`text-4xl mb-4 ${value.iconColor}`}
                                />
                                <h3 className="text-xl font-semibold mb-2 text-gray-600">{value.title}</h3>
                                <p className="text-gray-600">{value.description}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Call to Action */}
                <div className="bg-gradient-to-r from-[#f5f7fb] to-[#e0f7fa] py-10 mt-16 rounded-2xl shadow-lg text-center p-5">
                    <h1 className="text-gray-600 text-3xl sm:text-4xl md:text-5xl font-semibold p-2">
                        Ready to <span className="text-cyan-500">Transform</span> Your Vision?
                    </h1>
                    <p className="text-gray-600 mb-5 text-lg">
                        Let’s collaborate and bring your ideas to life. Reach out to us today!
                    </p>
                    <a href="/support" className="bg-gradient-to-r from-[#f6a5c0] to-[#d83299] text-white rounded-md px-6 py-3 transform transition-all duration-300 ease-in-out hover:scale-105 hover:bg-gradient-to-r hover:from-[#d83299] hover:to-[#f6a5c0] font-800 shadow-lg">
                        Contact Us
                    </a>
                </div>

                <ProgressSlider />
                <Footer />
            </div>
        </>
    );
};

export default About;
