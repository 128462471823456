import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import { FaTasks, FaEnvelope, FaUser, FaCog, FaProjectDiagram, FaSignOutAlt } from 'react-icons/fa';
import EmpPic from "../assets/employee/Reva.jpg";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary chart components
ChartJS.register(ArcElement, Tooltip, Legend);

const EmployeeDashboard = () => {
  const [activeSection, setActiveSection] = useState("Dashboard");
  const [employeeData, setEmployeeData] = useState({
    name: "Revati Joshi",
    role: "Frontend Developer",
    id: "EMP12345",
    picture: EmpPic,
    dob: "1992-05-15",
    contact: "123-456-7890"
  });

  const [editData, setEditData] = useState(employeeData);
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login-page");
  };

  // Dummy Salary Data
  const salaryData = [
    { month: "January", year: 2024, amount: 3500, status: "Paid" },
    { month: "February", year: 2024, amount: 3500, status: "Paid" },
    { month: "March", year: 2024, amount: 3500, status: "Paid" },
    { month: "April", year: 2024, amount: 3500, status: "Pending" },
  ];

  // Dummy Attendance Data
  const attendanceData = [
    { date: "2024-11-01", status: "Present", hoursWorked: 8 },
    { date: "2024-11-02", status: "Absent", hoursWorked: 0 },
    { date: "2024-11-03", status: "Present", hoursWorked: 7 },
    { date: "2024-11-04", status: "Leave", hoursWorked: 0 },
    { date: "2024-11-05", status: "Present", hoursWorked: 8 },
  ];

  // Sample projects with deadlines, team members, and progress
  const assignedProjects = [
    {
      title: "Project Alpha",
      deadline: new Date("2024-12-15"),
      progress: 65,
      team: [
        { name: "Alice", progress: 50 },
        { name: "Bob", progress: 75 },
        { name: "Charlie", progress: 60 }
      ]
    },
    {
      title: "Project Beta",
      deadline: new Date("2024-12-20"),
      progress: 40,
      team: [
        { name: "David", progress: 30 },
        { name: "Eve", progress: 50 },
        { name: "Frank", progress: 35 }
      ]
    },
  ];

  // Define Pie chart data
  const pieChartData = (progress) => ({
    labels: ["Progress", "Remaining"],
    datasets: [
      {
        data: [progress, 100 - progress],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        hoverBackgroundColor: ["#66BB6A", "#BDBDBD"],
      },
    ],
  });

  const handleEditSubmit = (e) => {
    e.preventDefault();
    setEmployeeData(editData); // Update employee details
    setActiveSection("Dashboard"); // Redirect to Dashboard
  };

  // Dashboard content
  const renderContent = () => {
    switch (activeSection) {
      case "Dashboard":
        return (
          <>
            {/* Dashboard Overview */}
            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
              {[{ title: "Assigned Projects", count: "5", color: "blue" },
              { title: "Upcoming Deadlines", count: "2", color: "green" },
              { title: "Messages", count: "3", color: "gray" }].map((card) => (
                <div
                  key={card.title}
                  className={`p-6 bg-${card.color}-100 rounded-lg shadow-sm hover:scale-105 transition-all ease-in-out duration-300 flex justify-between items-center`}
                >
                  <h2 className="text-sm text-gray-700">{card.title}</h2>
                  <p className="text-xl font-semibold text-gray-800">{card.count}</p>
                </div>
              ))}
            </section>

            {/* Assigned Projects Section */}
            <section className="bg-white p-6 rounded-lg shadow-sm mb-8">
              <h2 className="text-sm font-semibold mb-4 text-gray-700">Assigned Projects</h2>
              <div className="flex flex-wrap -mx-2">
                {assignedProjects.map((project, index) => (
                  <div
                    key={index}
                    className="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4"
                  >
                    <div className="p-4 border border-gray-300 rounded-md h-full">
                      <h3 className="text-md font-semibold text-gray-800">{project.title}</h3>
                      <p className="text-sm text-gray-600">Deadline: {project.deadline.toDateString()}</p>
                      <p className="text-sm text-gray-600">Progress: {project.progress}%</p>
                      <div className="p-2 mt-2">
                        <Pie data={pieChartData(project.progress)} width={80} height={80} />
                      </div>
                      <p className="font-semibold mt-4 text-gray-700">Team Members:</p>
                      <ul>
                        {project.team.map((member, i) => (
                          <li key={i} className="text-sm text-gray-600">
                            {member.name}: {member.progress}%
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </>
        );

      case "Profile":
        return (
          <div className="bg-white p-6 rounded-lg shadow-sm max-w-md mx-auto">
            <h2 className="text-lg font-semibold mb-4 text-center text-gray-700">Edit Profile</h2>
            <form onSubmit={handleEditSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">Name</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={editData.name}
                  onChange={(e) => setEditData({ ...editData, name: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">Role</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={editData.role}
                  onChange={(e) => setEditData({ ...editData, role: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">Date of Birth</label>
                <input
                  type="date"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={editData.dob}
                  onChange={(e) => setEditData({ ...editData, dob: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">Contact Info</label>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={editData.contact}
                  onChange={(e) => setEditData({ ...editData, contact: e.target.value })}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">Profile Picture</label>
                <input
                  type="file"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={(e) => setEditData({ ...editData, picture: URL.createObjectURL(e.target.files[0]) })}
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        );

      case "Salary Details":
        return (
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-4 text-gray-700">Salary Details</h2>
            <table className="min-w-full table-auto text-sm">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Month</th>
                  <th className="px-4 py-2 text-left">Year</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {salaryData.map((record, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">{record.month}</td>
                    <td className="px-4 py-2">{record.year}</td>
                    <td className="px-4 py-2">{record.amount}</td>
                    <td className="px-4 py-2">{record.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

      case "Attendance":
        return (
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-4 text-gray-700">Attendance</h2>
            <table className="min-w-full table-auto text-sm">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Date</th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left">Hours Worked</th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((record, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">{record.date}</td>
                    <td className="px-4 py-2">{record.status}</td>
                    <td className="px-4 py-2">{record.hoursWorked}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Navbar className="mb-10" />
      <div className="min-h-screen mt-16 bg-gray-100 flex flex-col lg:flex-row">
        {/* Sidebar */}
        <aside className="w-full lg:w-64 bg-white shadow-md p-6">
          <div className="text-center">
            <img
              src={employeeData.picture}
              alt="Employee"
              className="w-24 h-24 rounded-full mx-auto mb-4"
            />
            <h3 className="text-lg font-semibold text-gray-800">{employeeData.name}</h3>
            <h4 className="text-sm text-gray-500">{employeeData.role}</h4>
            <p className="text-sm text-gray-500">{employeeData.id}</p>
          </div>
          <nav className="mt-8">
            {[{ name: "Dashboard", icon: <FaTasks /> },
            { name: "Profile", icon: <FaUser /> },
            { name: "Settings", icon: <FaCog /> },
            { name: "Raise Query", icon: <FaEnvelope /> },
            { name: "Salary Details", icon: <FaProjectDiagram /> },
            { name: "Attendance", icon: <FaTasks /> }].map((item) => (
              <button
                key={item.name}
                onClick={() => setActiveSection(item.name)}
                className={`block px-6 py-3 text-left w-full text-gray-600 hover:bg-gray-50 hover:text-gray-800 flex items-center space-x-3 ${activeSection === item.name ? "bg-gray-200 text-gray-800" : ""
                  }`}
              >
                {item.icon}
                <span>{item.name}</span>
              </button>
            ))}
            <button
              className="block px-6 py-3 text-left w-full text-gray-600 hover:bg-gray-50 hover:text-gray-800 mt-8 flex items-center space-x-3"
              onClick={handleLogout}
            >
              <FaSignOutAlt />
              <span>Log Out</span>
            </button>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="flex-1 p-8 lg:p-12">{renderContent()}</main>
      </div>
      <Footer />
    </>
  );
};

export default EmployeeDashboard;
