import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const GetInTouch = () => {
    const navigate = useNavigate(); // Initialize navigate

    return (
        <section className="relative bg-gradient-to-r from-[#f5f7fb] to-[#e0f7fa] rounded-xl text-gray-600 py-20 flex items-center justify-center mt-10 shadow-lg">
            {/* Banner Content */}
            <div className="relative z-10 text-center px-6 sm:px-8 lg:px-12 max-w-5xl">
                {/* Main Heading */}
                <h1 className="text-4xl md:text-5xl font-semibold    leading-tight mb-4 md:mb-6 text-cyan-400 transform transition-all duration-300 ease-in-out hover:scale-105">
                    Welcome to Nexkites! <span className="text-cyan-500">...</span>
                </h1>

                {/* Motivational Subtitle */}
                <h3 className="text-lg md:text-xl leading-tight mb-8 text-gray-700">
                    Embark on a journey towards excellence. Discover opportunities that align with your passions!
                </h3>

                {/* Call to Action */}
                <p className="text-md md:text-lg mb-6 text-gray-800">
                    Ready to soar higher?
                    <span className="font-semibold text-gray-900"> Join us today and start your adventure!</span>
                </p>

                {/* Call to Action Button */}
                <button
                    onClick={() => navigate('/services')} // Navigate to services page on click
                    className="bg-gradient-to-r from-[#f6a5c0] to-[#d83299] text-white rounded-md px-6 py-3 transform transition-all duration-300 ease-in-out hover:scale-105 hover:bg-gradient-to-r hover:from-[#d83299] hover:to-[#f6a5c0] font-800 shadow-lg"
                >
                    Get Started
                </button>
            </div>

            {/* Background Design */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#f8e8e8] to-[#d0f5ff] opacity-30 rounded-xl"></div>
        </section>
    );
};

export default GetInTouch;
