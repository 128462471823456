import React from 'react';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import ErrorLogo from "../assets/logo/Nexkites KiteBlue.png";

const Error404 = () => {
    return (
        <>
            <Navbar className="mb-16" />
            <div className="flex flex-col items-center justify-center min-h-screen bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] mt-10 px-4">
                <img
                    src={ErrorLogo}
                    alt="Nexkites logo"
                    className="w-full max-w-lg animate-float" // Adjust max-width for smaller size and apply animation
                />
                <p className="text-lg sm:text-xl text-gray-500 text-center">
                    Oops! The page you're looking for is under construction.
                </p>
                <h1 className="text-4xl sm:text-5xl text-center font-semibold text-cyan-600 p-5">
                    Nexkites Team is Working On It!
                </h1>
                <div className="w-full sm:w-auto">
                    <a
                        href="/"
                        className="block px-6 py-3 mx-auto sm:px-8 sm:py-4 bg-black text-white font-800 rounded-lg shadow-lg hover:bg-black transition duration-300 ease-in-out text-center"
                    >
                        Go to Home
                    </a>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Error404;
