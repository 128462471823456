import React, { useState, useEffect } from 'react';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead

const PDFUploader = () => {
    const [pdfFiles, setPdfFiles] = useState([]);
    const navigate = useNavigate(); // Use the navigate hook

    // Load files from localStorage on component mount
    useEffect(() => {
        const storedFiles = JSON.parse(localStorage.getItem('pdfFiles')) || [];
        setPdfFiles(storedFiles);
    }, []);

    // Handle file input change
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newPdfFiles = files.map(file => ({
            file,
            previewUrl: URL.createObjectURL(file)
        }));

        const updatedFiles = [...pdfFiles, ...newPdfFiles];
        setPdfFiles(updatedFiles);
        localStorage.setItem('pdfFiles', JSON.stringify(updatedFiles));
    };

    // Remove a file from the preview list
    const handleRemoveFile = (index) => {
        const updatedFiles = pdfFiles.filter((_, i) => i !== index);
        setPdfFiles(updatedFiles);
        localStorage.setItem('pdfFiles', JSON.stringify(updatedFiles));
    };

    // Navigate back to the dashboard
    const goToDashboard = () => {
        navigate('/dashboard'); // Adjust the path to your dashboard route
    };

    return (
        <>
            <Navbar className="mb-16" />
            <div className="pdf-uploader text-center mt-16 p-5">
                <button onClick={goToDashboard} className="back-to-dashboard mb-4 bg-blue-500 text-white px-4 py-2 rounded">
                    Back to Dashboard
                </button>
                <input
                    type="file"
                    accept="application/pdf"
                    multiple
                    onChange={handleFileChange}
                    className="file-input p-2 border border-gray-300 rounded"
                />
                <div className="pdf-previews mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {pdfFiles.map((pdf, index) => (
                        <div key={index} className="pdf-preview-card p-4 border border-gray-300 rounded">
                            <embed
                                src={pdf.previewUrl}
                                type="application/pdf"
                                width="100%"
                                height="200px"
                                className="pdf-preview mb-2"
                            />
                            <div className="flex justify-between items-center">
                                <a href={pdf.previewUrl} download className="text-blue-500 hover:underline">
                                    Download
                                </a>
                                <button
                                    onClick={() => handleRemoveFile(index)}
                                    className="text-red-500 hover:underline"
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PDFUploader;
