import React, { useEffect, useState } from 'react';
import PreKite from "../assets/logo/Nexkites KiteBlue.png";

const Preloader = () => {
    const taglines = [
        "Launching Your Experience!",
        "Preparing for Lift-Off!",
        "Soaring to New Heights!",
        "The Journey Begins Now!",
        "Elevating Your Vision!",
        "Fasten Your Seatbelts!",
        "Gearing Up for Excellence!",
        "Unfolding Innovation!",
        "Setting Sail into Creativity!",
        "Your Adventure Awaits!",
    ];

    const [tagline, setTagline] = useState("");

    useEffect(() => {
        // Pick a random tagline on component mount
        const randomTagline = taglines[Math.floor(Math.random() * taglines.length)];
        setTagline(randomTagline);
    }, []);

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] flex flex-col items-center justify-center z-50">
            <div className="relative w-32 h-32">
                <img
                    src={PreKite}
                    alt="Nexkite Logo"
                    className="absolute inset-0 w-full h-full object-contain animate-fly"
                />
            </div>
            <p className="mt-8 text-xl font-800 text-cyan-600 animate-pulse">
                {tagline}
            </p>
        </div>
    );
};

export default Preloader;
