import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for React Router navigation
import Banner1 from "../assets/hiring/2.png";
import Banner2 from "../assets/hiring/3.png";
import Banner3 from "../assets/hiring/4.png";

const AdvBanner2 = () => {
    const images = [Banner1, Banner2, Banner3];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); // Change image every 2 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <section className="bg-cyan-20 relative text-center px-4 py-5 sm:px-8 lg:px-12  mt-5 rounded"> {/* Added margin-top */}
            {/* Background Pattern */}

            <div className="relative z-10">
                {/* Image showcasing different devices */}
                <div className="flex justify-center my-8">
                    <img
                        src={images[currentImageIndex]} // Dynamically changing image
                        alt="Responsive Design Showcase"
                        className="max-w-full w-auto h-auto"
                    />
                </div>

                {/* Subtitle */}
                <p className="text-2xl text-gray-700 font-light">
                    <span className="font-800 text-cyan-400">Shape</span> Your <span className="font-800 text-cyan-400">Future</span> With Nexkites!
                </p>

                {/* Button to Services Page */}
                <div className="mt-6">
                    <Link to="/careers">
                        <button className="bg-black text-white rounded-md px-6 py-2 font-800 text-sm sm:px-8 sm:py-3 md:px-10 md:py-4 hover:bg-[#d83299] transition duration-300">
                            Apply Now
                        </button>
                    </Link>
                </div>

            </div>
        </section>
    );
};

export default AdvBanner2;
