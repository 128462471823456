import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TheGrillSnap1 from "../assets/landing/TheGrillSnap.jpg";
import TheGrillSnap2 from "../assets/landing/TheGrillProducts.jpg";

const TheGrill = () => {
    const slides = [
        {
            image: TheGrillSnap1,
            heading: "Savor the Flavor!",
            subheading: "Experience the magic of flame-grilled perfection.",
            buttonText: "Explore Menu",
            link: "/services",
        },
        {
            image: TheGrillSnap2,
            heading: "Grilled to Perfection!",
            subheading: "Juicy meats, signature marinades, and unforgettable taste.",
            buttonText: "Discover Delights",
            link: "/services",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 2000); // Change slide every 2 seconds

        return () => clearInterval(interval);
    }, [slides.length]);

    return (
        <section className="bg-peach-100 text-center px-4 py-10 sm:px-8 lg:px-12 shadow-sm mt-10 rounded">
            <div className="relative z-10">
                <h1 className="text-red-500 font-800 text-3xl sm:text-3xl lg:text-4xl xl:text-4xl leading-tight">
                    {slides[currentIndex].heading}
                </h1>

                {/* Image */}
                <div className="flex justify-center my-8">
                    <img
                        src={slides[currentIndex].image}
                        alt="The Grill Showcase"
                        className="max-w-full w-auto h-auto rounded-lg shadow-lg"
                    />
                </div>

                {/* Subtitle */}
                <p className="text-xl text-gray-600 font-light">
                    {slides[currentIndex].subheading}
                </p>

                {/* Button */}
                <div className="mt-6">
                    <Link to={slides[currentIndex].link}>
                        <button className="bg-black text-white rounded-md px-6 py-2 font-800 text-sm sm:px-8 sm:py-3 md:px-10 md:py-4 hover:bg-black transition duration-300">
                            {slides[currentIndex].buttonText}
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default TheGrill;
