import React from 'react';
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faPalette,
    faMobileAlt,
    faFilm,
    faPaintBrush,
} from '@fortawesome/free-solid-svg-icons';
import ProgressSlider from '../constants/ProgressSlider';

const Pricing = () => {
    const navigate = useNavigate();

    const plans = [
        {
            title: 'Websites',
            description: 'Explore our website development services to boost your online presence.',
            price: '₹4999.00',
            extraInfo: 'Up to one year maintenance, excl. tax',
            discount: 'Save 40%',
            details: [
                'Basic website development',
                'Responsive design',
                'SEO-friendly structure',
                'Custom domain setup',
            ],
            icon: faGlobe,
            iconColor: 'text-blue-600',
            bgColor: 'bg-blue-50',
        },
        {
            title: 'Mobile Applications',
            description: 'Get mobile app development services for iOS and Android.',
            price: '₹9999.00',
            extraInfo: 'Up to one year maintenance, excl. tax',
            discount: 'Save 33%',
            details: [
                'Native and hybrid app development',
                'App store optimization',
                'Performance and security testing',
                'Post-launch support',
            ],
            icon: faMobileAlt,
            iconColor: 'text-green-500',
            bgColor: 'bg-green-50',
        },
        {
            title: 'UI/UX Designing',
            description: 'Transform your ideas into intuitive, user-friendly designs.',
            price: '₹7999.00',
            details: [
                'Custom UI/UX design',
                'Wireframing and prototyping',
                'User testing and feedback',
                'Responsive and adaptive designs',
            ],
            icon: faPalette,
            iconColor: 'text-pink-500',
            bgColor: 'bg-pink-50',
        },
        {
            title: 'Graphic Design',
            description: 'Creative and unique graphic design solutions.',
            price: '₹9999.00',
            details: [
                'Logo and branding',
                'Custom illustrations',
                'Marketing materials and brochures',
                'Social media graphics',
            ],
            icon: faPaintBrush,
            iconColor: 'text-purple-600',
            bgColor: 'bg-purple-50',
        },
        {
            title: 'Video & Animations',
            description: 'Stand out with custom videos and animations.',
            price: '₹4999.00',
            details: [
                'Custom explainer videos',
                '2D and 3D animations',
                'Motion graphics and effects',
                'Scriptwriting and storyboarding',
            ],
            icon: faFilm,
            iconColor: 'text-yellow-500',
            bgColor: 'bg-yellow-50',
        },
    ];

    return (
        <>
            <Navbar className="mb-10" />
            <div className="py-16 mt-10 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,188,212,0.13)_0,rgba(0,188,212,0)_50%,rgba(0,188,212,0)_100%)] selection:bg-cyan-300 selection:text-cyan-900">
                <div className="text-center mb-16 px-4">
                    <h1 className="text-4xl font-semibold text-gray-700 mb-10">Our Pricing Plans</h1>
                    <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
                        Choose the perfect plan for your business needs. We offer competitive and flexible
                        packages to help you succeed.
                    </p>
                    <p className="text-sm text-red-500 mt-3">
                        *Note: Prices may vary based on project scope and requirements.
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-8 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] rounded-2xl shadow-lg">
                    {plans.map((plan, index) => (
                        <div
                            key={index}
                            className={`flex flex-col justify-between w-full p-8 rounded-xl shadow-md border border-gray-200 ${plan.bgColor} transform transition-all hover:scale-105 hover:shadow-xl`}
                        >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={plan.icon}
                                    className={`text-6xl mb-4 ${plan.iconColor}`}
                                />
                                <h3 className="text-2xl font-semibold">{plan.title}</h3>
                                <p className="text-gray-600 my-4">{plan.description}</p>
                            </div>

                            <div className="text-center my-6">
                                <span className="text-lg font-medium text-green-800">Starting at</span>
                                <div className="text-4xl font-bold">{plan.price} <span className="text-lg">INR</span></div>
                                {plan.extraInfo && (
                                    <p className="text-sm text-gray-500">{plan.extraInfo}</p>
                                )}
                                {plan.discount && (
                                    <span className="bg-green-100 text-green-800 text-sm px-3 py-1 mt-2 rounded inline-block">
                                        {plan.discount}
                                    </span>
                                )}
                            </div>

                            <ul className="space-y-2 text-gray-700 mb-6">
                                {plan.details.map((detail, idx) => (
                                    <li key={idx} className="flex items-center gap-2">
                                        <span className="w-2 h-2 bg-black rounded-full inline-block" />
                                        {detail}
                                    </li>
                                ))}
                            </ul>

                            <button
                                className="mt-auto w-full py-3 bg-black text-white font-semibold rounded-md hover:bg-gray-800 transition"
                                onClick={() => navigate('/support')}
                            >
                                Get Started
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <ProgressSlider />
            <Footer />
        </>
    );
};

export default Pricing;
