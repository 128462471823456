import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faKey, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import BMimg1 from "../assets/Banner/Floating Duck.png"; // Add other images as needed

import { Link } from 'react-router-dom';

const BannerMiddle = () => {
  const images = [BMimg1];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative bg-peach-200 p-6 md:p-10 rounded-2xl shadow-lg flex flex-col md:flex-row items-center my-10 mx-auto">
      {/* Left Section - Text */}
      <div className="w-full md:w-1/2 mb-6 md:mb-0 text-center md:text-left">
        <h4 className="uppercase text-sm text-gray-600 font-semibold">E-commerce Simplified</h4>
        <h1 className="text-3xl md:text-4xl font-semibold text-gray-800 mt-2">
          Sell Your Products Online with Confidence.
        </h1>
        <p className="text-gray-600 mt-4 p-1">
          The most secure and reliable way to take your e-commerce business online.
        </p>

        {/* Features */}
        <ul className="mt-4 space-y-2 text-gray-600">
          <li className="flex items-center">
            <FontAwesomeIcon icon={faLock} className="mr-2" /> Enhanced Security
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faKey} className="mr-2" /> Single Sign-On (SSO)
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faUser} className="mr-2" /> Centralized User Management
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faCog} className="mr-2" /> Tailored Control
          </li>
        </ul>

        {/* Button */}
        <Link to={'/services'}>
          <button className="mt-6 px-5 py-3 bg-black text-white font-300 rounded-lg shadow hover:bg-black transition">
            Explore Services
          </button>
        </Link>
      </div>

      {/* Right Section - Image Slider */}
      <div className="w-full md:w-1/2 relative">
        {/* Background Shapes */}
        <div className="absolute inset-0 flex justify-end">
          <div className="bg-[Purple] w-1/4 h-full rounded-r-xl"></div>
          <div className="bg-[#FAF1DC] w-3/4 h-full rounded-r-[50px]"></div>
        </div>

        {/* Image Slider */}
        <div className="relative z-10 p-4">
          <img
            src={images[currentImageIndex]} // Dynamically changing image
            alt="eCommerce platform illustration"
            className="rounded-xl object-cover shadow-lg w-full h-full transition-all duration-500"
          />
        </div>
      </div>
    </div>
  );
};

export default BannerMiddle;
